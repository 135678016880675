<template>
  <div>
    <ag-table
      :data-source-api="getAll"
      :grid-options="gridOptions"
      :permissions="permissions"
      :is-entities-response="false"
      :rtl="false"
      @emitExportToExcel="exportToExcel"
      :style="{fontSize: '16px'}"
    />
    <vs-popup
      title="تفاصيل التبرع"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 px-4 mt-2">
        <div>
          <label>الاسم :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.donorName"
          />
        </div>

        <div>
          <label>المبلغ :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.amount"
          />
        </div>

        <div>
          <label>الهاتف المحمول :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.donorPhoneNumber"
          />
        </div>
        <div>
          <label>المنطقة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.district"
          />
        </div>
        <div>
          <label>شارع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.street"
          />
        </div>
        <div
          class="col-span-2"
        >
          <label>جانب :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.beside"
          />
        </div>
        <div>
          <label> تاريخ التبرع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.createdAt"
          />
        </div>
        <div>
          <label>نوع التبرع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.type"
          />
        </div>
        <div>
          <label>اسم الجابي :</label>
          <vs-input
            placeholder=""
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.driverName"
          />
        </div>
        <div>
          <label> رقم الجابي :</label>
          <vs-input
            placeholder=""
            disabled
            color="#5E445A"
            dir="ltr"
            class="w-full mt-3"
            v-model="donation.driverPhoneNumber"
          />
        </div>
        <div v-if="donation.program">
          <label>البرنامج :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.program.name"
          />
        </div>
        <div v-if="donation.campaign">
          <label>الحملة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.campaign.name"
          />
        </div>
        <div v-if="donation.emergency">
          <label>الحالة الطارئة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.emergency.name"
          />
        </div>
      </div>

      <div class="flex justify-end items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>


  </div>
</template>

<script>
import AgTable from "@/app/shared/shared-components/ag-grid/AgTableServerSide";
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell.vue";
import {RepositoryFactory} from "@/app/donations-website/donations-repositories/donationsRepositoryFactory";

const donationRepo = RepositoryFactory.get("donationsRepository");

export default {
  name: "DonationsTable",
  components: {
    AgTable
  },
  props: {
    filter: {type: String, required: true}
  },
  data() {
    return {
      gridApi: null,
      popupActiveDetails: false,
      donation: {
        id: '',
        donorName: '',
        donorPhoneNumber: '',
        type: '',
        amount: '',
        programId: '',
        campaignId: '',
        emergencyId: '',
        driverName: '',
        driverPhoneNumber: '',
        districtName: ''
      },
      gridOptions: null,
      permissions: {
        add: '',
        delete: 'delete_donations',
        edit: ''
      }
    };
  },
  methods: {
    getAll(query) {
      if (this.filter === 'program')
        return donationRepo.fetchAllDonationsByProgram(query, this.$route.params.id).then((response => response));
      else if (this.filter === 'campaign')
        return donationRepo.fetchAllDonationsByCampaign(query, this.$route.params.id).then((response => response));
      else if (this.filter === 'emergency')
        return donationRepo.fetchAllDonationsByEmergency(query, this.$route.params.id).then((response => response));
      else
        return donationRepo.fetchAllDonations(query, this.$route.params.id).then((response => response));
    },
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          suppressSizeToFit: true,
          checkboxSelection: true,
          filter: 'agNumberColumnFilter',
          sort: 'desc'
        },
        {
          headerName: 'اسم المتبرع',
          field: 'donorName',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الرقم',
          field: 'donorPhoneNumber',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'نوع التبرع',
          field: 'type',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: ['PROGRAM', 'EMERGENCY', 'CAMPAIGN', 'SADAKA', 'ZAKA', 'KAFALE', "KAFARA"]
          },
          valueGetter: arabicDonationTypeGetter
        },
        {
          headerName: 'المبلغ',
          field: 'amount',
          filter: 'agNumberColumnFilter'
        },
        {
          headerName: 'اسم البرنامج',
          field: 'program.name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'اسم الحملة',
          field: 'campaign.name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'اسم الحالة',
          field: 'emergency.name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'المنطقة',
          field: 'districtName',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'شارع',
          field: 'street',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'جانب',
          field: 'beside',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'اسم الجابي',
          field: 'driverName',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'رقم الجابي',
          field: 'driverPhoneNumber',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'تاريخ التبرع',
          field: 'createdAt',
          filter: 'agDateColumnFilter'
        },
        {
          headerName: 'الإجراءات',
          valueGetter:
            function getObject(params) {
              return params.data;
            },
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            deleteRecord: function (record) {
              self.deleteDonation(record);
            },
            actions: ["view","delete"],
            permissions: self.permissions
          },
          sortable: false
        },
      ];
    },
    viewRecordDetails(record) {
      this.donation = record;
      this.popupActiveDetails = true;
    },
    exportToExcel() {
      donationRepo.exportDonationsToExcel();
    },
    async deleteDonation(record) {
      const res = await donationRepo.deleteDonation(record.id);
      if (res.status === 200) this.gridApi.refreshServerSideStore({purge: true});
    }
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
window.arabicDonationTypeGetter = function (params) {
  switch (params.data.type) {
    case 'PROGRAM':
      return 'برنامج';
    case 'CAMPAIGN':
      return 'حملة';
    case 'EMERGENCY':
      return 'حالة';
    case 'SADAKA':
      return 'صدقة';
    case 'ZAKA':
      return 'زكاة';
    case 'KAFALE':
      return 'كفالة';
    case 'KAFARA':
      return 'كفارة';
  }
};
</script>

<style scoped>
#map {
  height: 220px;
}

.vs-popup--title {
  background-color: #5E445A !important;
  text-align: center !important;
}

.vs-popup--title h3 {
  color: white !important;
  padding: 12px 20px !important;
}

.vs-textarea {
  font-family: 'Cairo', sans-serif !important;
}

.sectionName {
  font-weight: 700;
  font-size: 17px;
  color: #5E445A;
}

</style>
